jQuery(function($){
	$(document).ready(function() {

	/**
	 * Code fired on homepage
	 *
	 *	@author Przemysław Hernik
	 *	@date 03.02.2017
	 *	@see https://toddmotto.com/mastering-the-module-pattern/
	 */

	homeJS = (function(){

		var

			_hello = 'I\'m homeJS module!',

			init = function(){
				consoleLoaded();
			},

			finalize = function(){

			},

			consoleLoaded = function(){
				console.log(_hello);
			}
		;

		return {
			init: init,
			finalize: finalize
		};

	})();

	});
});
